import moment from "moment";



const DateMixin = {
    data(){
        return {
            monthList: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
        }
    },
    methods: {
        formatDate(date, format="DD/MM/YYYY"){
            if (date) {
                return moment(String(date)).format(format);
            }
        },
        stringifyReadingDate(date){
            return date.substring(3,5)+" "+this.monthList[Number(date.substring(0,2))-1];
        },
        stringifySeasonDate(month, day) {
            return (this.monthList.findIndex((el) => (el === month)) + 1).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            }) + "-" + day.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            });
        }
    }
}

export default DateMixin;
